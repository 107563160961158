import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Services from "../components/services"
import AboutPanel from "../components/about-panel"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import theme from "../theme"
import SupportUs from "../components/supportus"

const SupportUsPage = () => {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Layout>
          <SEO title="Support us" />
          <SupportUs />
        </Layout>
      </ThemeProvider>
    </React.Fragment>
  )
}

export default SupportUsPage
