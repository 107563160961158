import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Box, Grid, Hidden, IconButton, Paper, StepConnector, withStyles } from '@material-ui/core';
import Bar from '../components/bar';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Image from "./image"
import { Modal } from '@mui/material';
import { DonateForm } from './donate/DonateForm';
import CloseIcon from '@mui/icons-material/Close';

const SupportUs = () => {

    function createData(
        type: string,
        cost: string,
    ) {
        return { type, cost };
    }

    const rows = [
        createData('Full Day', "Rs 10,000"),
        createData('Breakfast', "Rs 4,000"),
        createData('Lunch', "Rs 4,000"),
        createData('Dinner', "Rs 5,000"),
        createData('Special meals', "Rs 10,000"),
    ];

    const steps = [
        {
            label: 'Becoming a volunteer',
            description: `Becoming a volunteer and helping us with your time and talents`,
        },
        {
            label: 'Spending time and celebrating ',
            description:
                'Spending time and celebrating special occasions in your life with our children. Our children love it when you find time to visit and play with them.',
        },
        {
            label: 'Sponsoring a child ',
            description: `Sponsoring a child by donating Rs 10,000 a year`,
        },
        {
            label: 'Sponsoring meals',
            description: `Sponsoring meals for the children `,
        },
        {
            label: 'Praying for us and our ministry',
            description: `Praying for us and our ministry `,
        },
    ];

    const handleNext = () => {
        handleOpen();
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "80vw",
        bgcolor: 'background.paper',
        p: 4,
        marginTop: 50,
        marginBottom: 50
    };

    const QontoConnector = withStyles({
        root: {
            width: "90%"
        },
        button: {
        },
        actionsContainer: {
        },
        resetContainer: {
        },
        contentRoot: {
            borderColor: "red"
        },
        connectorLine: {
            borderColor: "red"
        }

    })(StepConnector);

    return (

        <div style={{ paddingTop: 30 }}>
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <Grid container spacing={3} style={{ paddingBottom: 20 }} alignItems="center" direction="column">
                    <Typography style={{ display: "block" }} variant="h5">Support us</Typography>
                    <Bar />
                </Grid>
            </Box>
            <Grid container spacing={3} style={{}} alignItems="center">
                <Grid
                    item={true}
                    sm={6}
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Hidden xsDown>
                        <div style={{ width: "70%", textAlign: "justify" }}>
                            <Image
                                alt="helpinghand.webp"
                                filename="helpinghand.webp"
                            />
                        </div>
                    </Hidden>
                    <div style={{ width: "100%", textAlign: "justify" }}>
                        <Typography
                            variant="subtitle1"
                            style={{ margin: "10px 10px 10px 20px", color: "#154b68" }}
                            className="body-font"
                        >
                            Jyothis Special School and Therapy Centre is a nonprofit organization run by the immense generosity of people all around the world. We provide completely free education and vocational training to special children. We also provide therapeutic services, counseling, bus facility, food and accommodation to the children at subsidized rates, which are affordable for everyone. We require 5 Lakhs Indian Rupees every month to meet the running expenses of the school. In the past, we were blessed to have the right resources at the right time to continue our mission and we hope to relentlessly continue this mission with your support.

                        </Typography>
                    </div>

                </Grid>
                <Grid
                    item={true}
                    xs={12}
                    sm={6}
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    style={{ paddingLeft: 40, paddingRight: 40 }}
                >
                    <Typography
                        variant="subtitle1"
                        style={{ margin: "0px 0px 20px 0px", color: "#154b68" }}
                        className="body-font"
                    >
                        Here is how you can participate in our mission:
                    </Typography>

                    <Stepper activeStep={steps.length} orientation="vertical">
                        {steps.map((step, index) => (
                            <Step key={step.label} active={true} >
                                <StepLabel>
                                    <Typography style={{ color: "#154b68" }}>{step.description}</Typography>
                                </StepLabel>
                                <StepContent>
                                            {(index === 3) ?
                                                <TableContainer component={Paper} style={{ display: "inline-block" }}>
                                                    <Table size="small" aria-label="a dense table" >
                                                        <TableBody>
                                                            {rows.map((row) => (
                                                                <TableRow
                                                                    key={row.type}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    <TableCell component="th" scope="row" >
                                                                        {row.type}
                                                                    </TableCell>
                                                                    <TableCell align="right">{row.cost}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                : null}
                                        <div>
                                            {(index === steps.length - 1) ?
                                                <Button
                                                    variant="contained"
                                                    onClick={handleNext}
                                                    sx={{ mt: 1, mr: 1 }}
                                                >
                                                    Donate now
                                                </Button>
                                                : null}
                                        </div>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                </Grid>
            </Grid>

            <Modal style={{ outline: 'none', margin: 50, height: '90vh', overflowY: 'scroll', overflowX: 'hidden' }}
                sx={{ margin: { xs: "10px !important", sm: "50" } }}
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <DonateForm handleModalClose={handleClose} />
                </Box>
            </Modal>
        </div>
    )
}

export default SupportUs